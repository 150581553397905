import React from 'react';
import styles from './Brands.module.css';

const Brands = () => {
  return (
    <div className={styles.container} id="brands">
      <span className={`headingLargeText ${styles.brandText}`}>OUR BRANDS</span>
    </div>
  );
};

export default Brands;
